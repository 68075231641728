exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-download-private-instagram-js": () => import("./../../../src/pages/download-private-instagram.js" /* webpackChunkName: "component---src-pages-download-private-instagram-js" */),
  "component---src-pages-download-video-instagram-js": () => import("./../../../src/pages/download-video-instagram.js" /* webpackChunkName: "component---src-pages-download-video-instagram-js" */),
  "component---src-pages-es-download-video-instagram-js": () => import("./../../../src/pages/es/download-video-instagram.js" /* webpackChunkName: "component---src-pages-es-download-video-instagram-js" */),
  "component---src-pages-es-igtv-video-download-js": () => import("./../../../src/pages/es/igtv-video-download.js" /* webpackChunkName: "component---src-pages-es-igtv-video-download-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-es-instagram-photo-download-js": () => import("./../../../src/pages/es/instagram-photo-download.js" /* webpackChunkName: "component---src-pages-es-instagram-photo-download-js" */),
  "component---src-pages-es-instagram-reels-video-download-js": () => import("./../../../src/pages/es/instagram-reels-video-download.js" /* webpackChunkName: "component---src-pages-es-instagram-reels-video-download-js" */),
  "component---src-pages-es-instagram-story-download-js": () => import("./../../../src/pages/es/instagram-story-download.js" /* webpackChunkName: "component---src-pages-es-instagram-story-download-js" */),
  "component---src-pages-how-to-download-instagram-video-js": () => import("./../../../src/pages/how-to-download-instagram-video.js" /* webpackChunkName: "component---src-pages-how-to-download-instagram-video-js" */),
  "component---src-pages-how-to-download-instagram-videos-on-iphone-js": () => import("./../../../src/pages/how-to-download-instagram-videos-on-iphone.js" /* webpackChunkName: "component---src-pages-how-to-download-instagram-videos-on-iphone-js" */),
  "component---src-pages-igtv-video-download-js": () => import("./../../../src/pages/igtv-video-download.js" /* webpackChunkName: "component---src-pages-igtv-video-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instagram-photo-download-js": () => import("./../../../src/pages/instagram-photo-download.js" /* webpackChunkName: "component---src-pages-instagram-photo-download-js" */),
  "component---src-pages-instagram-reels-video-download-js": () => import("./../../../src/pages/instagram-reels-video-download.js" /* webpackChunkName: "component---src-pages-instagram-reels-video-download-js" */),
  "component---src-pages-instagram-story-download-js": () => import("./../../../src/pages/instagram-story-download.js" /* webpackChunkName: "component---src-pages-instagram-story-download-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

